exports.onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-image (Safari, IE)
  if (typeof window.IntersectionObserver === `undefined`) {
    require(`intersection-observer`)
  }

  // Object-fit/Object-position polyfill for gatsby-image (IE)
  const testImg = document.createElement(`img`)
  if (
    typeof testImg.style.objectFit === `undefined` ||
    typeof testImg.style.objectPosition === `undefined`
  ) {
    require(`object-fit-images`)()
  }

  // IE 11 for some reason doesn't have Number.isNan,
  // but do have global window.isNaN
  if (typeof Number.isNaN === `undefined`) {
    Number.isNaN =
      window.isNaN ||
      function(a) {
        return a
      }
  }
}
