// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/cloud/gatsbyjs.com/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-docs-page-js": () => import("/opt/build/repo/cloud/gatsbyjs.com/src/templates/docs-page.js" /* webpackChunkName: "component---src-templates-docs-page-js" */),
  "component---src-templates-page-js": () => import("/opt/build/repo/cloud/gatsbyjs.com/src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-gatsby-days-index-js": () => import("/opt/build/repo/cloud/gatsbyjs.com/src/templates/gatsby-days/index.js" /* webpackChunkName: "component---src-templates-gatsby-days-index-js" */),
  "component---src-templates-preview-index-js": () => import("/opt/build/repo/cloud/gatsbyjs.com/src/templates/preview/index.js" /* webpackChunkName: "component---src-templates-preview-index-js" */),
  "component---src-templates-guide-default-index-js": () => import("/opt/build/repo/cloud/gatsbyjs.com/src/templates/guide-default/index.js" /* webpackChunkName: "component---src-templates-guide-default-index-js" */),
  "component---src-templates-integrations-index-js": () => import("/opt/build/repo/cloud/gatsbyjs.com/src/templates/integrations/index.js" /* webpackChunkName: "component---src-templates-integrations-index-js" */),
  "component---src-templates-how-it-works-index-js": () => import("/opt/build/repo/cloud/gatsbyjs.com/src/templates/how-it-works/index.js" /* webpackChunkName: "component---src-templates-how-it-works-index-js" */),
  "component---src-templates-guide-react-index-js": () => import("/opt/build/repo/cloud/gatsbyjs.com/src/templates/guide-react/index.js" /* webpackChunkName: "component---src-templates-guide-react-index-js" */),
  "component---src-templates-about-us-index-js": () => import("/opt/build/repo/cloud/gatsbyjs.com/src/templates/about-us/index.js" /* webpackChunkName: "component---src-templates-about-us-index-js" */),
  "component---src-templates-guides-list-index-js": () => import("/opt/build/repo/cloud/gatsbyjs.com/src/templates/guides-list/index.js" /* webpackChunkName: "component---src-templates-guides-list-index-js" */),
  "component---src-templates-why-gatsby-index-js": () => import("/opt/build/repo/cloud/gatsbyjs.com/src/templates/why-gatsby/index.js" /* webpackChunkName: "component---src-templates-why-gatsby-index-js" */),
  "component---src-templates-landing-page-index-js": () => import("/opt/build/repo/cloud/gatsbyjs.com/src/templates/landing-page/index.js" /* webpackChunkName: "component---src-templates-landing-page-index-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/cloud/gatsbyjs.com/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dashboard-js": () => import("/opt/build/repo/cloud/gatsbyjs.com/src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-privacy-policy-js": () => import("/opt/build/repo/cloud/gatsbyjs.com/src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("/opt/build/repo/cloud/gatsbyjs.com/src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-terms-of-use-js": () => import("/opt/build/repo/cloud/gatsbyjs.com/src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/cloud/gatsbyjs.com/.cache/data.json")

